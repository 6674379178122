import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Grid, Modal, Box } from '@mui/material';
import { useState } from "react";
import { makeStyles } from '@mui/styles'
import ImageSlider from "./ImageSlider";

const useStyles = makeStyles({
    modalBox: {
        position: 'absolute',
        width: '80%',
        height: '80%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    },
    imageContainer: {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '100%', // Maintain aspect ratio (1:1 for square)
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover', // Cover the entire area
        cursor: 'pointer',
    },
});

const Gallery = () => {
    const [openModal, setOpenModal] = useState(false);
    const images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((image) => ({ label: image, src: `assets/img/gallery/img${image}.jpeg` })
    );

    const classes = useStyles();

    const handleImageClick = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Header hideRoutes={true} />
            <section style={{ paddingTop: 100 }} id="portfolio" class="portfolio">
                <div class="container" >
                    <div className="image-list-container">
                        <div class="section-title">
                            <h2>Gallery</h2>
                        </div>
                        <Grid container spacing={2}>

                            {images.map((image, index) => (
                                <Grid item xs={6} sm={4} md={3} key={index}>
                                    <div className={classes.imageContainer}>
                                        <img
                                            src={image.src}
                                            alt={index}
                                            className={classes.image}
                                            onClick={() => handleImageClick(image.src)}
                                        />
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        <Modal open={openModal} onClose={handleCloseModal}>
                            <Box className={classes.modalBox}>
                                <ImageSlider images={images} />
                            </Box>
                        </Modal>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Gallery;