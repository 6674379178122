function Footer() {
    return (
        <footer id="footer">
            <div class="container d-md-flex py-4">

                <div class="me-md-auto text-center text-md-start">
                    <div class="copyright">
                        &copy; Copyright <strong><span>The Indian Foundation</span></strong>. All Rights Reserved
                    </div>
                    <div class="credits">
                        Designed by <b>Bhavya Sharma</b>
                    </div>
                </div>
                <div class="social-links text-center text-md-end pt-3 pt-md-0">
                    <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                    <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                    <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;