import AwesomeSlider from "react-awesome-slider";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import { withRouter } from "react-router"

import withAutoplay from "react-awesome-slider/dist/autoplay";
import AwsSliderStyles from "react-awesome-slider/dist/styles.css";
import { useEffect } from "react";

const AutoplaySlider = withAutoplay(AwesomeSlider);

function KhulaManch() {

    const data = [
        { img: '/assets/img/portfolio/khula-manch-1.jpg' },
        { img: '/assets/img/portfolio/khula-manch-2.jpg' },
        { img: '/assets/img/portfolio/khula-manch-3.jpg' },
        { img: '/assets/img/portfolio/khula-manch-4.jpg' },
        { img: '/assets/img/portfolio/khula-manch-5.jpg' },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header hideRoutes={true} />
            <section class="breadcrumbs">
                <div class="container">

                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Project Details</li>
                    </ol>
                    <h2>Khula Manch</h2>

                </div>
            </section>
            <section id="portfolio-details" class="portfolio-details">
                <div class="container">

                    <div class="row gy-4">

                        <div class="col-lg-6">
                            <AutoplaySlider
                                play={true}
                                cssModule={AwsSliderStyles}
                                interval={5000}
                            >
                                {data.map(slide => (
                                    <div data-src={slide.img} />

                                    // <div className='SlideContainer'>
                                    //     <div style={{ backgroundImage: `url(${slide.img})` }}></div>
                                    //     {/* <img
                                    //         alt=""
                                    //         className='SliderImage'
                                    //         src={slide.img}
                                    //     /> */}
                                    // </div>
                                )
                                )}
                            </AutoplaySlider>
                        </div>

                        <div class="col-lg-6" style={{ paddingLeft: '55px' }}>
                            <div class="portfolio-info">
                                <h3>Project information</h3>
                                <ul>
                                    <li><strong>Category</strong>: Public Service</li>
                                    {/* <li><strong>Client</strong>: ASU Company</li> */}
                                    <li><strong>Project date</strong>: 01 March, 2020</li>
                                    {/* <li><strong>Project URL</strong>: <a href="#">www.example.com</a></li> */}
                                </ul>
                            </div>
                            <div class="portfolio-description">
                                <h2>Project details</h2>
                                <p>
                                    Khula Manch aims to create community based sustainable model to develop and strengthen local capacity to find local solutions towards better quality of life. This is an experimental project with holistic approach to understand and devise community-based solutions for their own needs, through and by them. The communities are provided technical support and linkages to care for themselves and their children with a long-term, sustained and holistic approach. Key components of the program are
                                </p>
                                <ul>
                                    <li>
                                        Awareness generation and orientation of communities
                                    </li>
                                    <li>
                                        Establish centers and empowers youths to take initiatives.
                                    </li>
                                    <li>
                                        Link them with potential market sources and create local employment opportunities
                                    </li>
                                    <li>
                                        Provide platform for discussing community issues focusing women empowerment

                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <Footer />
        </>
    );
}

export default withRouter(KhulaManch);