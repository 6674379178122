import { useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Blogs = () => {

    const blogPosts = [
        {
            title: 'Rising Against The Odds',
            shortDescription: `Rekha an extraordinary woman balances the roles of a loving mother, a
            devoted wife, and most importantly, the sole pillar of stability by providing
            financial support to her family. She works as a house help in multiple
            households, while her husband takes up several interim jobs to make ends
            meet. She is the key breadwinner of the family and how there are days when her husband barely
            brings anything to the plate.`,
            description: `Rekha an extraordinary woman balances the roles of a loving mother, a
            devoted wife, and most importantly, the sole pillar of stability by providing
            financial support to her family. She works as a house help in multiple
            households, while her husband takes up several interim jobs to make ends
            meet. She is the key breadwinner of the family and how there are days when her husband barely
            brings anything to the plate. Rekha has a family of seven, a widowed mother in law and
            her sister who started living with them post pandemic, both of them are
            elderly. An unmarried sister in law of who is 32 years old and two teenagers,
            a girl and a boy of ages 16 and 15 respectively. Lastly Rekha and her
            husband Suresh, who is 5 years elder then Rekha. Rekha got married when
            she was only 17 years old. She when the family got hit by a tragedy of
            losing their family’s only employed member Rekha’s lawfather-in-
            law, after his demise circumstances forced Rekha to be the bread
            earner for the family while her husband struggled to get a decent job. Despite being the primary earner, Rekha has a very little say in her family
            or financial matters, All major decisions are taken by her mother-in-law or her husband, , which brings
            minimal authority to her as a family member. When asked about domestic violence Rekha was taken aback a little which suggested that she
            wasn’t comfortable to talk about it. We live in a country where living life with dignity prevails as a
            fundamental right (Article 21) yet societally talking about domestic violence or any grievances
            related to a women’s life are stigmatized to this day. They need a safe space to talk about their
            issues without a fear of judgment, The Indian Foundation is committed to creating an inclusive space where women and
            girls feel comfortable to share about their day-to-day life and problems associated with
            it`,
            publishedAt: 'August 10, 2023',
            publishedBy: 'Ananya Sharma',
            images: []
        }
    ]

    useEffect(() => {
        // window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header hideRoutes={true} />
            <section class="breadcrumbs">
                <div class="container">

                    <ol>
                        <li><a href="/">Home</a></li>
                        <li>Blogs</li>
                    </ol>
                    <h2>Blogs</h2>

                </div>
            </section>
            <section id="blog" class="blog">
                <div class="container" data-aos="fade-up">

                    <div class="row">

                        <div class="col-lg-12 entries">

                            {blogPosts.map(blog => <article class="entry">

                                <div class="entry-img">
                                    <img src="assets/img/blog/blog-1.jpg" alt="" class="img-fluid" />
                                </div>

                                <h2 class="entry-title">
                                    <a href="#?">{blog.title}</a>
                                </h2>
                                <div class="entry-meta">
                                    <ul>
                                        <li class="d-flex align-items-center"><i class="bi bi-person"></i>{blog.publishedBy}</li>
                                        <li class="d-flex align-items-center"><i class="bi bi-clock"></i>{blog.publishedAt}</li>
                                        {/* <li class="d-flex align-items-center"><i class="bi bi-chat-dots"></i>12 Comments</li> */}
                                    </ul>
                                </div>
                                <div class="entry-content">
                                    <div>{blog.description}</div>
                                    {/* <div class="read-more">
                                        <a href="#?">Read More</a>
                                    </div> */}
                                </div>
                            </article>)}

                            {/* <div class="blog-pagination">
                                <ul class="justify-content-center">
                                    <li><a href="#">1</a></li>
                                    <li class="active"><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                </ul>
                            </div> */}

                        </div>

                        {/* <div class="col-lg-4">

                            <div class="sidebar">

                                <h3 class="sidebar-title">Search</h3>
                                <div class="sidebar-item search-form">
                                    <form action="">
                                        <input type="text" />
                                        <button type="submit"><i class="bi bi-search"></i></button>
                                    </form>
                                </div>

                                <h3 class="sidebar-title">Categories</h3>
                                <div class="sidebar-item categories">
                                    <ul>
                                        <li><a href="#">General <span>(25)</span></a></li>
                                        <li><a href="#">Lifestyle <span>(12)</span></a></li>
                                        <li><a href="#">Travel <span>(5)</span></a></li>
                                        <li><a href="#">Design <span>(22)</span></a></li>
                                        <li><a href="#">Creative <span>(8)</span></a></li>
                                        <li><a href="#">Educaion <span>(14)</span></a></li>
                                    </ul>
                                </div>

                                <h3 class="sidebar-title">Recent Posts</h3>
                                <div class="sidebar-item recent-posts">
                                    {blogPosts.map(blog => (
                                        <div class="post-item clearfix">
                                            <img src="assets/img/blog/blog-recent-1.jpg" alt="" />
                                            <h4><a href="blog-single.html">{blog.title}</a></h4>
                                            <time datetime="2020-01-01">{blog.publishedAt}</time>
                                        </div>
                                    ))}


                                </div>

                                <h3 class="sidebar-title">Tags</h3>
                                <div class="sidebar-item tags">
                                    <ul>
                                        <li><a href="#">App</a></li>
                                        <li><a href="#">IT</a></li>
                                        <li><a href="#">Business</a></li>
                                        <li><a href="#">Mac</a></li>
                                        <li><a href="#">Design</a></li>
                                        <li><a href="#">Office</a></li>
                                        <li><a href="#">Creative</a></li>
                                        <li><a href="#">Studio</a></li>
                                        <li><a href="#">Smart</a></li>
                                        <li><a href="#">Tips</a></li>
                                        <li><a href="#">Marketing</a></li>
                                    </ul>
                                </div>

                            </div>

                        </div> */}

                    </div>

                </div>
            </section>
            <Footer />
        </>
    );
}

export default Blogs;