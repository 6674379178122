function Publications() {

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('Report1.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Mental Health Research Report Rajasthan 2023.pdf';
                alink.click();
            })
        })
    }

    return (
        <section id="about" class="about section-bg">
            <div class="container" data-aos="fade-up">
                <div class="section-title" style={{ paddingBottom: 0, paddingTop: '20px' }}>
                    <h2>Research Report</h2>

                </div>
                <div class="row no-gutters">
                    <div class="content d-flex align-items-stretch">
                        <div class="content" style={{ textAlign: 'center' }}>
                            <h3>Mental Health Perceptions among Adolescent and Young people</h3>
                            <p>
                                Mental health is fundamental to good health and well-being, and it influences social and economic
                                outcomes throughout life. Childhood and adolescence are crucial for laying a foundation for healthy
                                development and good mental health. The increasing burden of mental health problems among this
                                population is a growing concern globally. Most mental disorders begin before 25 years of age, more
                                often between 11–18 years. The burden associated with common mental disorders (depressive and
                                anxiety disorders) rises in childhood and peaks in adolescence and early to middle age (10–29 years). A
                                meta-analysis estimated that the global prevalence for any mental disorder among children and
                                adolescents is 13.4%. Multiple factors are associated with poor mental health among adolescents,
                                including domestic violence, child abuse, bullying, peer pressure, substance abuse, human
                                immunodeficiency virus (HIV) infection, and teenage pregnancy.
                            </p>
                            <button class="about-btn" onClick={onButtonClick} ><span>Download Pdf</span> <i class="bx bx-download"></i></button>
                        </div>
                    </div>
                    {/* <div class="col-xl-7 d-flex align-items-stretch">
                        <div class="icon-boxes d-flex flex-column justify-content-center">
                            <div class="row">
                                <div
                                    class="col-md-6 icon-box"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                >
                                    <i class="bx bx-receipt"></i>
                                    <h4>Corporis voluptates sit</h4>
                                    <p>
                                        Consequuntur sunt aut quasi enim aliquam quae harum
                                        pariatur laboris nisi ut aliquip
                                    </p>
                                </div>
                                <div
                                    class="col-md-6 icon-box"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                >
                                    <i class="bx bx-cube-alt"></i>
                                    <h4>Ullamco laboris nisi</h4>
                                    <p>
                                        Excepteur sint occaecat cupidatat non proident, sunt in
                                        culpa qui officia deserunt
                                    </p>
                                </div>
                                <div
                                    class="col-md-6 icon-box"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                >
                                    <i class="bx bx-images"></i>
                                    <h4>Labore consequatur</h4>
                                    <p>
                                        Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut
                                        maiores omnis facere
                                    </p>
                                </div>
                                <div
                                    class="col-md-6 icon-box"
                                    data-aos="fade-up"
                                    data-aos-delay="400"
                                >
                                    <i class="bx bx-shield"></i>
                                    <h4>Beatae veritatis</h4>
                                    <p>
                                        Expedita veritatis consequuntur nihil tempore laudantium
                                        vitae denat pacta
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default Publications;