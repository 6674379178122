import * as React from 'react';
import Box from '@mui/material/Box';
import AwsSliderStyles from "react-awesome-slider/dist/styles.css";
import AwesomeSlider from "react-awesome-slider";

function ImageSlider({ images }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AwesomeSlider
                play={true}
                cssModule={AwsSliderStyles}
                interval={5000}
            >
                {images.map(slide => (
                    <div className='SlideContainer gallery' style={{justifyContent: 'center'}}>
                        <img
                            alt=""
                            className='SliderImage'
                            src={slide.src}
                        />
                    </div>
                )
                )}
            </AwesomeSlider>
        </Box>
    );
}

export default ImageSlider;
