import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import AwsSliderStyles from "react-awesome-slider/dist/styles.css";
import "./Project.css";
import { Link } from "react-router-dom/cjs/react-router-dom";

const data = [
    {
        img: '/assets/img/portfolio/khula-manch-3.jpg',
        name: "Khula Manch",
        href: '/project/khula-manch',
        review:
            "Khula Manch aims to create community based sustainable model to develop and strengthen local capacity to find local solutions towards better quality of life. This is an experimental project with holistic approach to understand and devise community-based solutions for their own needs, through and by them. The communities are provided technical support and linkages to care for themselves and their children with a long-term, sustained and holistic approach"
    },
    {
        img: '/assets/img/portfolio/homo-happiens.jpg',
        name: "Homo-Happiens",
        review:
            "The Indian Foundation is piloting the mental health interventions in schools and local communities in Jaipur and Ujjain. Under the project Homo-Happiens an Adolescent Mental Health and Wellbeing program curriculum is being developed and mental health sessions/workshops are conducted for the young and adolescent people in the selected locations. Needy young and adolescent people requiring specialized care and support are being provided required services. "
    },
    {
        img: '/assets/img/portfolio/climate-action2.jpg',
        name: "Climate action initiative – Oxygen Hub",
        review:
            "The Indian Foundation is facilitating awareness camps for raising community awareness on plantation - issues and causes of deforestation and its impact on climate change. We aim for local communities to lead the plantation and monitor each tree regularly for atleast six months. The foundation coordinates with policymakers and businesses to reduce deforestation and mitigate the impacts of climate change. In addition we support smallholder farmers and indigenous communities in implementing sustainable land use practices and provide them with the resources and incentives necessary to participate in reforestation programs."
    }
];

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Projects() {

    return (
        <section id="testimonials" class="testimonials">
            <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2>Projects</h2>
                </div>

                <div
                    class=""
                    data-aos="fade-up"
                    data-aos-delay="100"
                >
                    <div className="SliderBox">
                        <AutoplaySlider
                            play={true}
                            cssModule={AwsSliderStyles}
                            interval={50000}
                        >
                            {data.map(slide => (

                                <div className='SlideContainer'>
                                    <div className='SlideInfo'>

                                        <Link to={slide.href} onClick={() => window.location.href = slide.href}>
                                            <div className='Title'>
                                                <h1>{slide.name} {slide.name === 'Khula Manch' && <i class="bx bx-link-external"></i>}</h1>
                                            </div>
                                        </Link>
                                        <div className='Overview'>{slide.review}</div>
                                    </div>
                                    <img
                                        alt=""
                                        className='SliderImage'
                                        src={slide.img}
                                    />
                                </div>
                            )
                            )}
                        </AutoplaySlider>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Projects;