import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './Components/Home/Home';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import KhulaManch from './Components/Projects/KhulaManch/KhulaManch';
import Blogs from './Components/Blog/Blog';
import Gallery from './Components/Gallery/Gallery';

function App() {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route exact path="/project/khula-manch" >
          <KhulaManch />
        </Route>
        <Route exact path="/blogs" >
          <Blogs />
        </Route>
        <Route exact path="/gallery" >
          <Gallery />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;