// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
// import { getAnalytics } from "firebase/analytics";
import Axios from 'axios'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB2u-jdXCJjHXbiEGyQU3rw358y3n4nhgY",
    authDomain: "the-indian-foundation.firebaseapp.com",
    databaseURL: "https://the-indian-foundation-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "the-indian-foundation",
    storageBucket: "the-indian-foundation.appspot.com",
    messagingSenderId: "780743715540",
    appId: "1:780743715540:web:d87b77728f52d40376a941",
    measurementId: "G-620SEM6Y5W"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const db = firebase.firestore()

export { Axios, db }