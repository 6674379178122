import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function FocusArea() {

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <section id="tabs" class="tabs" style={{ marginTop: '50px' }}>
            <div class="container" data-aos="fade-up">

                <ul class="nav nav-tabs row d-flex" style={{ justifyContent: 'center' }}>
                    <li class="nav-item col-3" style={{ width: '33.33%' }}>
                        <a class="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                            <i class="ri-gps-line"></i>
                            <h4 class="d-none d-lg-block">Gender Equality</h4>
                        </a>
                    </li>
                    <li class="nav-item col-3" style={{ width: '33.33%' }}>
                        <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                            <i class="ri-sun-line"></i>
                            <h4 class="d-none d-lg-block">Climate change </h4>
                        </a>
                    </li>
                    <li class="nav-item col-3" style={{ width: '33.33%' }}>
                        <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                            <i class="ri-body-scan-line"></i>
                            <h4 class="d-none d-lg-block">Overall Wellbeing</h4>
                        </a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div class="tab-pane active show" id="tab-1">
                        <div class="row" style={{ alignItems: 'center' }}>
                            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
                                <h3>Gender Equality and prevention of sexual exploitation, abuse, and harassment</h3>
                                <p>
                                    Gender equality and addressing sexual abuse are crucial and interconnected topics that require attention and collective effort.
                                    Gender equality refers to the equal rights, opportunities, and access to resources for individuals of all genders.
                                    It aims to eliminate discrimination and ensure fairness in various aspects of life, including education, employment, and social interactions.
                                    When it comes to sexual abuse, it is a deeply troubling issue that affects individuals of all genders.
                                    The Indian Foundation creates safe spaces where survivors can openly share their experiences and receive the support they need.
                                    TIF is committed for educating and raising awareness about consent, healthy relationships, and bystander intervention for preventing and addressing sexual abuse.
                                    Additionally, we take collective actions for promoting empathy, understanding, and inclusivity, where everyone feels safe and valued, regardless of their gender.
                                    We strongly believe that together we can create a world where all individuals have equal opportunities and are free from the fear of sexual abuse.
                                </p>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                                <img src="assets/img/gender-equality.jpg" alt="" class="img-fluid" style={{ maxHeight: '400px' }} />
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab-2">
                        <div class="row">
                            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                <p>
                                    Global warming refers to the long-term increase in the Earth's average surface temperature due to the emission of greenhouse gases, such as carbon dioxide, which trap heat in the Earth's atmosphere and prevent it from escaping into space.
                                    Burning fossil fuels for electricity and transportation, deforestation, and agriculture all contributes to it.
                                    The effects of global warming include rising sea levels, more frequent and severe weather events, and damage to ecosystems and wildlife.
                                    The Indian Foundation is committed to take action to reduce greenhouse gas emissions and mitigate the impacts of global warming on our planet.
                                    Planting trees can help combat climate change by reducing the amount of greenhouse gases in the atmosphere. TIF understands that planting trees is not only good for the environment, but it's also good for our health and the health of future generations.
                                    Within the first month of project implementation we have planted more than 2000 trees in close vicinity of Jaipur and formed 20 “vriksh mitra samuh” (tree friends group) through community involvement.
                                </p>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="assets/img/global-warming2.jpg" alt="" class="img-fluid" style={{ maxHeight: '400px' }} />
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="tab-3">
                        <div class="row">
                            <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                <p>
                                    The Indian Foundation is committed to enhance the overall wellbeing of individuals through innovative
                                    approaches and research aimed at improving physical, mental, emotional, and social health factors. Our goal is
                                    to enhance the happiness and life satisfaction of individuals by introducing a range of locally curated solutions.
                                    Currently, our primary focus revolves around addressing the pressing issue of mental health. The aftermath of
                                    the Covid-19 pandemic had an unprecedented toll on mental health, affecting people across the globe. Among
                                    adolescents, in particular, various factors such as domestic violence, child abuse, bullying, peer pressure,
                                    substance abuse and teenage pregnancy have contributed to a significant increase in mental health disorders. In
                                    a study published in The Lancet in October 2021, it was reported that India saw a whopping 35% rise in mental
                                    health disorders, affecting both genders almost equally.
                                    Investing in adolescents can yield a "triple dividend" - it not only improves their current health but also sets the
                                    stage for enhanced wellbeing throughout their lives and contributes to the health of future generations. The
                                    Indian Foundation is passionate about extending a helping hand to the needy individuals including marginalized
                                    and vulnerable communities. We strive to empower them with knowledge and encourage the adoption of
                                    informed, responsible, and healthy behaviors.
                                </p>
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2 text-center">
                                <img src="assets/img/wellbeing.jpg" alt="" class="img-fluid" style={{ maxHeight: '400px' }} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    );
}

export default FocusArea;