import React, { useState } from 'react'
import { Axios, db } from '../../Firebase/firebaseConfig'

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        subject: ''
    })

    const handleChange = (e, feild) => {
        const data = formData

        data[feild] = e.target.value;
        setFormData(formData)
    }

    const handleSubmit = event => {
        event.preventDefault()
        // sendEmail()
        setFormData({
            name: '',
            email: '',
            message: '',
            subject: ''
        })
    }

    const sendEmail = () => {
        console.log('Form data ', formData);
        // Axios.post(
        //     'https://us-central1-the-indian-foundation.cloudfunctions.net/sendEmail',
        //     formData
        // )
        //     .then(res => {

        //         console.log('Response ', res);
        //         db.collection('emails').add({
        //             name: formData.name,
        //             email: formData.email,
        //             message: formData.message,
        //             time: new Date(),
        //         })
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     })
    }


    return (
        <section id="contact" class="contact">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Contact</h2>
                </div>

                <div class="row" data-aos="fade-up" data-aos-delay="100">

                    <div class="col-lg-6">

                        <div class="row">
                            <div class="col-md-12">
                                <div class="info-box">
                                    <i class="bx bx-map"></i>
                                    <h3>Our Address</h3>
                                    <p>B 1 A Khadi Colony, Hatwara road, Jaipur, Rajasthan (302006)</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box mt-4" style={{ paddingBottom: '56px' }}>
                                    <i class="bx bx-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>indianfoundation.jpr@gmail.com</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info-box mt-4">
                                    <i class="bx bx-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <p>+91 8209840589<br />+91 9717666494</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-6">
                        <form method="post" role="form" class="php-email-form">
                            <div class="row">
                                <div class="col form-group">
                                    <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required onChange={(e) => handleChange(e, 'name')} />
                                </div>
                                <div class="col form-group">
                                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required onChange={(e) => handleChange(e, 'email')} />
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required onChange={(e) => handleChange(e, 'subject')} />
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" name="message" rows="5" placeholder="Message" required onChange={(e) => handleChange(e, 'message')}></textarea>
                            </div>
                            <div class="my-3">
                                <div class="loading">Loading</div>
                                <div class="error-message"></div>
                                <div class="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div class="text-center"><button type="submit" onClick={handleSubmit}>Send Message</button></div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default ContactUs;