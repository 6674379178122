import React from 'react';
import { useState } from 'react';

const Header = (props) => {
    const [selected, setSelectedTab] = useState('home')


    const onTabSelect = (id) => {
        if (id !== 'home') {
            const element = document.getElementById(id)
            const y = element.getBoundingClientRect().top + window.scrollY;
            window.scroll({
                top: y - 40,
                behavior: 'smooth'
            });
        }
        else {
            window.scrollTo(0, 0)
        }
        setSelectedTab(id)
    }

    return (
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container d-flex align-items-center">
                <h1 class="logo me-auto">
                    <span class="main_logo"><img src="/logo.png" alt=""></img></span>
                    <a href="/">The Indian Foundation</a>
                </h1>
                {!props.hideRoutes ? <nav id="navbar" class="navbar order-last order-lg-0">
                    <ul>
                        <li><a class={`nav-link scrollto ${selected === 'home' ? 'active' : ''}`} onClick={() => onTabSelect('home')}>Who We Are</a></li>
                        <li><a class={`nav-link scrollto ${selected === 'tabs' ? 'active' : ''}`} onClick={() => onTabSelect('tabs')}>Focus Area</a></li>
                        <li><a class={`nav-link scrollto ${selected === 'about' ? 'active' : ''}`} onClick={() => onTabSelect('about')} >Our Publications</a></li>
                        <li><a class={`nav-link scrollto ${selected === 'testimonials' ? 'active' : ''}`} onClick={() => onTabSelect('testimonials')}>Projects</a></li>
                        {/* <li><a class={`nav-link scrollto ${selected === '' ? 'active' : ''}`} href="#team">Team</a></li>*/}
                        <li><a href='/gallery'>Gallery</a></li>
                        <li><a href='/blogs'>Blog</a></li>
                        <li><a class={`nav-link scrollto ${selected === 'contact' ? 'active' : ''}`} onClick={() => onTabSelect('contact')}>Contact</a></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                </nav> : null}
            </div>
        </header>
    );
}

export default Header;