import React from 'react';
import FocusArea from '../FocusArea/FocusArea';
import Publications from '../OurPublications/Publications';
import Projects from '../Projects/Project';
import ContactUs from '../ContactUs/ContactUs';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

function Home() {

    return (
        <>
            <Header />
            <section id="hero" class="d-flex align-items-center">
                <div class="container home-wrapper" data-aos="zoom-out" data-aos-delay="100">
                    <div class="row">
                        <div class="col-xl-6">
                            <h1>Quintessential for Being</h1> <br />
                            <h2>The Indian Foundation (TIF) is a not-for profit organisation established in 2010, to provide consultancy solutions and technical assistance for developing support and to strengthen government institutes and community initiatives. We partner with governments, health experts and local communities to build the skills and systems. TIF aims to create an equitable society that lives in a harmonious way that sustains. TIF upholds the highest ethical standards. </h2>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Counts /> */}
            <FocusArea />
            <Publications />
            <Projects />
            <ContactUs />
            <Footer />
        </>
    );
}

export default withRouter(Home);